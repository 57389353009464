@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.related-topics-container {
	background-color: $brand-gray-very-light;
	border-top: 4px solid;
	padding: 20px 25px;
	border-bottom: 1px solid $brand-gray-light;
	margin-bottom: 0;
	margin-top: 40px;
	h2 {
		font-weight: 600;
		font-size: 20px;
		margin-top: 0;
		margin-bottom: 1rem;
		text-transform: capitalize;
	}
	p {
		margin: 1rem 0;
	}
	figure a {
		color: $brand-text-color;
	}
	ul {
		margin-left: 0;
		list-style: none;
		li {
			margin-bottom: 12px;
			padding-left: 0;
			line-height: 1.4;
			a {
				font-size: 18px;
				color: $ch-secondary-purple;
				text-decoration: none;
			}
		}
	}

	&.departments {
		border-top-color: $ch-secondary-purple;
		h2 {
			color: $ch-secondary-purple;
		}
	}
	&.content,
	&.stories {
		border-top-color: $ch-secondary-blue;
		h2 {
			color: $ch-secondary-blue;
		}
	}

	article {
		figure {
			margin-bottom: 24px;
		}
		figcaption {
			font-size: 16px;
			line-height: 1.4;
			padding: 12px 0;
		}
		.media-type {
			display: flex;
			align-items: center;
			margin-bottom: 6px;
			.article {
				background-repeat: no-repeat;
				height: 19px;
				width: 19px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMinYMid' viewBox='0 0 256 224'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23414042;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earticle-icon%3C/title%3E%3Cpath class='a' d='M252,0H36a4,4,0,0,0-4,4V204a12,12,0,0,1-24,0V32a4,4,0,0,0-8,0V204a20,20,0,0,0,20,20H236a20,20,0,0,0,20-20V4A4,4,0,0,0,252,0Zm-4,204a12,12,0,0,1-12,12H36a19.9,19.9,0,0,0,4-12V8H248V204Z'/%3E%3Cpath class='a' d='M20,208a4,4,0,0,0,4-4V20a4,4,0,0,0-8,0V204A4,4,0,0,0,20,208Z'/%3E%3Cpath class='a' d='M60,152h64a4,4,0,0,0,4-4V76a4,4,0,0,0-4-4H60a4,4,0,0,0-4,4v72A4,4,0,0,0,60,152Zm4-72h56v64H64V80Z'/%3E%3Cpath class='a' d='M60,32H228a4,4,0,0,0,0-8H60A4,4,0,0,0,60,32Z'/%3E%3Cpath class='a' d='M60,56H228a4,4,0,0,0,0-8H60A4,4,0,0,0,60,56Z'/%3E%3Cpath class='a' d='M60,176H228a4,4,0,0,0,0-8H60A4,4,0,0,0,60,176Z'/%3E%3Cpath class='a' d='M228,72H148a4,4,0,0,0,0,8h80A4,4,0,0,0,228,72Z'/%3E%3Cpath class='a' d='M228,96H148a4,4,0,0,0,0,8h80A4,4,0,0,0,228,96Z'/%3E%3Cpath class='a' d='M228,120H148a4,4,0,0,0,0,8h80A4,4,0,0,0,228,120Z'/%3E%3Cpath class='a' d='M228,144H148a4,4,0,0,0,0,8h80A4,4,0,0,0,228,144Z'/%3E%3Cpath class='a' d='M228,192H148a4,4,0,0,0,0,8h80A4,4,0,0,0,228,192Z'/%3E%3C/svg%3E");
				padding-right: 31px;
				> div {
					color: $brand-text-color;
					font-size: 16px;
				}
			}
		}
		.link-text {
			color: $ch-secondary-blue;
			font-size: 18px;
		}
	}

	a * {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}
}
