@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.embeddedServiceHelpButton {
	z-index: 100;
}

.embeddedServiceHelpButton .helpButton .uiButton {
	background-color: $ch-secondary-purple !important;
	font-family: "Arial", sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
	outline: 1px solid $ch-secondary-purple !important;
}

.embeddedServiceSidebarExtendedHeader {
	background-color: $brand-link-color-tint !important;
}

//For updating the colour of the message
.message {
	color: $ch-corporate-white !important;
}

.embeddedServiceLiveAgentStateChat .chatSessionStartTime {
	color: $brand-link-color-tint !important;
}

.chasitor[c-chatMessage_chatMessage] {
	color: white;
	background-color: $ch-secondary-purple !important;
}

.embeddedServiceLiveAgentStateChatMenuMessage .rich-menu-item {
	color: $ch-secondary-purple !important;
}

.embeddedServiceLiveAgentStateChatInputFooter .chasitorText {
	background-color: $brand-gray-very-light;
}

button[embeddedService-chatHeader_chatHeader] {
	font-size: 9px !important;
}

.wrapper p {
	margin-bottom: 0;
}

a[c-basic_basic] {
	color: $ch-secondary-purple !important;
	font-size: inherit;
}

.richText[c-chatMessage_chatMessage] a {
	color: $ch-secondary-purple !important;
	text-decoration: underline !important;
}

.data[c-basic_basic] {
	padding: 10px 5px;
}

.embeddedServiceHelpButton .uiButton {
	min-width: 8.5em;
}

.slds-icon_xx-small {
	width: 1rem;
}
